import React, { useContext } from "react"
import { Link, useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"


import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { device } from "../device"

import logo from "../../images/logos/saven_logo.svg"
import link from "../../images/social/link.svg"
import face from "../../images/social/face.svg"
import insta from "../../images/social/insta.svg"
import pt2020 from "../../images/pt2020.svg"

const Footer = () => {
  const { t } = useTranslation(["footer"])
  const lng = useContext(I18nextContext).language;

  return (
    <StyledFooter>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo da Saven" className="logo segment" />
      </Link>
      <div className="segment">
        <p className={font.aGGD + " segTitle"}>{t("saven.title")}</p>
        {t("saven.links", { returnObjects: true }).map(
          ({ link, linkTxt }, i) => (
            <Link to={link} key={"savenLink" + i}>
              <p className={font.aGGB}>{linkTxt}</p>
            </Link>
          )
        )}
      </div>
      <div className="segment">
        <p className={font.aGGD + " segTitle"}>{t("luaCheiaSaven.title")}</p>
        {t("luaCheiaSaven.links", { returnObjects: true }).map(
          ({ link, linkTxt }, i) => (
            <Link to={link} key={"luaLink" + i}>
              <p className={font.aGGB}>{linkTxt}</p>
            </Link>
          )
        )}
      </div>
      <div className="segment right">
        <div className="segTitle social">
          <a
            href="https://www.linkedin.com/company/luacheiavv"
            className="socialLink"
            target="_blank"
            rel="noreferrer"
          >
            <img src={link} alt="Logo do LinkedIn" className="logoSmall" />
          </a>
          <a
            href="https://www.facebook.com/savenportugal"
            className="socialLink"
            target="_blank"
            rel="noreferrer"
          >
            <img src={face} alt="Logo do Facebook" className="logoSmall" />
          </a>
          <a
            href="https://www.instagram.com/luacheia_saven"
            className="socialLink"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="Logo do Instagram" className="logoSmall" />
          </a>
        </div>
        {t("address.links", { returnObjects: true }).map(
          ({ link, linkTxt }, i) => (
            <a
              href={link}
              key={"addressLink" + i}
              rel="noreferrer"
              target="_blank"
            >
              <p className={font.aGGB}>{linkTxt}</p>
            </a>
          )
        )}
      </div>
      <div className="pt2020">
        <a href="https://saven.pt/assets/compete2020/SAVEN_PT2020.pdf" target="_blank">
          <img src={pt2020} alt="Logo do PT2020" className="logo segment" />
        </a>
      </div>
      <div className="created">
        <a href="https://www.invisual.pt" target="_blank" className={font.aGGB}>Created: Invisual.pt</a>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.div`
  background: #150e04;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff8f4;
  padding: 40px 25px;
  border-top: solid 2px #fff9f4;

  @media ${device.tabletM} {
    flex-direction: unset;
    justify-content: space-between;
    align-items: unset;
  }

  @media ${device.desktop} {
    margin-left: 200px;
    padding: 3.3vw 8vw 3.3vw 5vw;
  }

  @media ${device.desktopL} {
    margin-left: 15.65%;
  }

  .logo {
    width: 150px;
    align-self: center;
    fill: white;
  }

  .pt2020 {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .pt2020 a {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
  }

  .pt2020 a img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .created{
    width: 100%;
    margin-top: 1em;
    text-align: right;
    transform: translateY(-100%);
  }

  @media screen and (max-width: 1200px) {
    .created{
      transform: translateY(100%);
      text-align: center;
    }
  }

  @media screen and (max-width: 520px) {
    .pt2020 a {
      width: 90%;
    }
  }

  .socialLink {
    margin: 10px 10px 0 10px;

    @media ${device.tabletM} {
      margin: 0 0 0 25px;
    }
  }

  .logoSmall {
    height: 20px;
  }

  .segment {
    text-align: center;
    margin: 10px 0;

    @media ${device.tabletM} {
      text-align: left;
      margin: 0;
    }

    p {
      font-size: 14px;
    }
  }

  .social {
    display: flex;
    margin: 10px 0 20px 0;

    @media ${device.tabletM} {
      margin: 0;
    }

    & > a {
      margin-top: 10px;

      @media ${device.tabletM} {
        margin-top: 0;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0%;

    @media ${device.tabletM} {
      align-items: flex-end;
    }
  }

  .segTitle {
    @media ${device.tabletM} {
      margin-bottom: 30px;
    }
  }

  .inv {
    user-select: none;
    visibility: hidden;
  }
`
