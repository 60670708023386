import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SidebarMobile from '../components/sidebar/mobile'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Footer from "../components/footer/footer"

import Sidebar from "../components/sidebar/sidebar"
// import Media from "../components/homescreen/homeScreenMedia"
import Newsletter from '../components/newsletter/newsletter'

const MediaPage = ({data}) => {
  const breakpoints = useBreakpoint()

  return(
    <Layout color="#150e04">
    <SEO title="Media" />
        {/* OLD */}
        {/* <Media 
            dataNews={data.newsJson.newsletter} 
            sidebar={
            !breakpoints.mobile ?
                <Sidebar switchMenu="left" white='isWhite'/>
            :
                <SidebarMobile/>
            }
        />
        */}

        {!breakpoints.mobile ?
            <Sidebar switchMenu="left"/>
            :
            <SidebarMobile brown/>
        }
        <Newsletter/>
        {breakpoints.mobile &&
            <Footer/>
        }
    </Layout>
  )
}

export default MediaPage


export const Json = graphql`
  query media {
    newsJson{
      newsletter{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`