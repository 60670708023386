import React, { useState, useRef } from "react"
import font from "../../fonts/fonts.module.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import styled from "styled-components"
import { device } from "../device"
import { Button } from "../buttons"

const Sobre = () => {
  const {t} = useTranslation()

  // OPEN NEWSLETTER CONTAINER
  const firstRef = useRef(null)
  const secondRef = useRef(null)
  const thanksRef = useRef(null)
  const errorRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)

  function openNewsletter(e) {
    e.preventDefault();
    firstRef.current.style.display = 'none'
    secondRef.current.style.display = 'flex'       
  }

  function closeNewsletter(e) {
    e.preventDefault();
    firstRef.current.style.display = 'block'
    secondRef.current.style.display = 'none'
    thanksRef.current.style.display = 'none'
    errorRef.current.style.display = 'none'
    nameRef.current.value = ''
    emailRef.current.value = '' 
  }
  // ---


  // SEND TO NEWSLETTER 
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  function changeEmailHandler(event){
    setEmail(event.target.value)
  }

  function changeNameHandler(event){
    setName(event.target.value)
  }

  function handleSubmit(e){
    e.preventDefault();
    addToMailchimp(email, {
      FNAME: name
    })
    .then(({ result }) => {
      result === 'success' ? (thanksRef.current.style.display = 'block') : (errorRef.current.style.display = 'block')
    })
  }
  // ---

  return(
    <StyledSobre>
      <div className='first-container' ref={firstRef}>
        <h2 className={font.nSR + " sobreTitle"} dangerouslySetInnerHTML={{ __html: t('media:title')}}></h2>
        <p className={font.nSR + " sobreTxt"}>
            {t('media:mediaTxt')}
            <span className='news-btn' onClick={openNewsletter} onKeyUp={openNewsletter} role='button' tabIndex={0}>{t('media:linkTxt')}</span>
        </p>
        <h3 className={font.nSI + " sobreSubtitle"} dangerouslySetInnerHTML={{ __html: t('media:newsTitle')}}></h3>
        <div className='newsletters'>
            <iframe
                src="https://www.news.luacheia-saven.pt/newsletters/"
                height="100%"
                width="100%"
                frameBorder="0"
                title='newsletter'
                loading="lazy"
            />
        </div>
      </div>
      {/* FORM */}
      <div className='second-container' ref={secondRef}>
            <h2 className={font.nSR + " sobreSubtitle"}>{t('media:subscribe.title')}</h2>
            <div className='newsletter-form'>
                <form className='subscribe' onSubmit={handleSubmit}>
                  <input type='text' value={name} ref={nameRef} onChange={changeNameHandler} name='fname' id='name' className='sobreTxt' placeholder={t('media:subscribe.placeholderName')} required />
                  <input type='email' value={email} ref={emailRef} onChange={changeEmailHandler} name='email' id='email' className='sobreTxt' placeholder={t('media:subscribe.placeholderEmail')} required />
                  <Button className='submit-btn' type='submit'>
                    {t('media:subscribe.btn')} 
                  </Button>
                </form>
            </div>
            <p className={font.nSR + " mediaTxtSubscribe"}>
              <span className='news-btn' onClick={closeNewsletter} onKeyUp={closeNewsletter} role='button' tabIndex={0}>
                {t('media:subscribe.back')}
              </span>
              <p className={font.nSR + " success"} ref={thanksRef}>{t('media:subscribe.thanks')}</p>
              <p className={font.nSR + " error"} ref={errorRef}>{t('media:subscribe.error')}</p>
            </p>
          </div>
          {/* --- */}
    </StyledSobre>
  )
}

export default Sobre

const StyledSobre = styled.div`
      display: flex;
      flex-direction: column;
      text-align: center;
      position: relative;
      padding: 80px 25px 40px;
      max-width: 700px;
      margin: 0 auto;
      min-height:100vh;

    @media ${device.desktop}{
      max-width:unset;
      margin:unset;
      justify-content: center;
      width: 50%;
      margin: auto;
    }

    @media ${device.desktopL}{
      width: 45%;
    }

    .sobreTitle{
      z-index: 1;
      color: #fff8f4;
      font-size: 35px;

      @media ${device.desktop}{
          font-size: 46px;
      }
    }

    .sobreTxt{
      z-index: 1;
      color: #fff8f4;
      font-size: 18px;
      overflow-y:auto;
      -webkit-overflow-scrolling: touch;
      max-height:350px;
      padding: 0 25px;
      letter-spacing:-0.5px !important;

      @media ${device.desktop}{
        font-size: 20px;
        overflow-y:auto;
       -webkit-overflow-scrolling: touch;
      }

        .news-btn{
            cursor:pointer;
            text-decoration:underline;

            &:focus{
                outline:none;
            }
        }
    }

    .sobreSubtitle{
        color: #fa4f4f;
        margin-bottom: 20px;
        z-index: 1;
        margin-top:80px;
    }

    .newsletters{
        height:350px;
    }

    .second-container{
        display:none;
        padding: 50px 10%;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #fff;
        width:100%;

        @media ${device.desktop}{
          padding: 10%;
        }

        .subscribe{
            input {
                text-align: center;
                background: none;
                border: none;
                border-bottom: 1px solid #fff;
                outline: none;
                width:100%;
                margin-top: 25px;
                color:#fff;
                font-family: 'NSTR';
                font-size:18px;
                letter-spacing:-0.5px !important;

                &::placeholder{
                    color:#fff;
                }

                @media ${device.tabletM}{
                    margin:20px 0;
                }
            }

            .submit-btn{
                border-radius:0;
                padding:10px 30px;
                font-family: 'NSTR';
                font-size:20px;
                letter-spacing:0;
                transition: all 0.3s;
                color:#fff;
                width:100%;
                margin: 20px 0;
                letter-spacing:-0.5px !important;

                &:hover{
                    background:#fff;
                    color:#3e3028;
                }
            }
        }
        
        .mediaTxtSubscribe{
            color: #fff;
            font-size:20px;
            display:flex;
            justify-content:space-between;
            align-items:center;

            .news-btn{
                cursor:pointer;
                text-decoration:underline;

                &:focus{
                    outline:none;
                }
            }

            .success, .error{
                display:none;
                color: #fff;
                font-size:15px;
            }
        }
    }
`